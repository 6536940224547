import { NextPage } from 'next'
import { Head } from '~/components/elements/Head'
import Page from '~/components/pages/Courses'

const Container: NextPage = () => {
  return (
    <>
      <Head title="講座" />
      <Page />
    </>
  )
}

export default Container
